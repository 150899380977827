import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const LogoContainer = styled.div`
  margin: 40px 0;

  > div {
    margin: 0 auto;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    > div {
      margin: 0;
    }
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="O2 (BAK) Customer Story - Rotaready"
          description="Here's how one of O2's largest franchises, has taken its workforce management to the next level and made life easier for the entire business since working with Rotaready."
          url="customer-stories/retail-o2-bak"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Retail assistant helping a customer"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Retail" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <LogoContainer>
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    style={{ height: '50px' }}
                    alt="BAK O2 logo"
                  />
                </LogoContainer>

                <H3 uistyle="brand160" text="Here's how one of O2's largest franchises, has taken its workforce management to the next level and made life easier for the entire business since working with Rotaready." />
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="24" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="150" />
              <StatSubtitle text="staff" />
            </StatItem>

            <StatItem>
              <StatHeadline text="562%" />
              <StatSubtitle text="ROI" />
            </StatItem>

            <StatItem>
              <StatHeadline text="48 hours" />
              <StatSubtitle text="better deployed each week" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="BAK - an O2 franchise" />
              </Content>

              <Content>
                <TextBlock text="O2 franchise BAK operates 23 stores and 1 call centre across Yorkshire, the North East and the South of England, making it one of the largest O2 franchises in the UK." />
              </Content>

              <Content>
                <TextBlock text="When BAK started out 10 years ago they implemented a workforce management solution almost immediately. However, they soon identified a number of issues and decided it didn't deliver in the areas they needed it to, so returned to using spreadsheets to build staff rotas and monitor time and attendance." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="The frustrations of manual staff scheduling" />
              </Content>

              <Content>
                <TextBlock text="As the company grew, the frustrations of this manual approach became too much for the business. The franchise was seeing inaccuracies in staff clocking-in & out, which was resulting in both high wage costs and employees missing out on overtime pay. But this wasn't the only struggle. Holiday was also being tracked manually which was a time heavy, administrative nightmare for managers and the HR team." />
              </Content>

              <Content>
                <TextBlock text="So in January 2018, following a customer recommendation, BAK turned to Rotaready to help tackle its staff scheduling challenges head on. With 24 sites to set up the franchise opted for a staggered roll-out of 6-8 sites per month, so were fully on-board with Rotaready within 3 months. The implementation was a smooth process and when asked, 100% of management staff were either extremely satisfied or satisfied with the roll-out, training and continual support provided by Rotaready." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="The perfect partner" />
              </Content>

              <Content>
                <TextBlock text="Having made full use of Rotaready's features from the get go, the group immediately saw the positive impact Rotaready was having on the day to day running of the business. Not only has it saved store managers a significant amount of time on building rotas and monitoring attendance, BAK are now building more accurate schedules than ever." />
              </Content>

              <Content>
                <Blockquote
                  text="Since implementing Rotaready, time spent on workforce management tasks such as building rotas and tracking attendance, has reduced significantly. In fact, our store managers have reduced the time they spend on such tasks by 22%"
                  attribution="Chris Tongue - Operations Manager"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline.childImageSharp.gatsbyImageData}
                alt="Mobile phones on display"
              />
            </Section>

            <Section>
              <Content>
                <TextBlock text="Monitoring staff time and attendance with perfect accuracy has become a doddle thanks to the introduction of image capture upon clocking-in. Discrepancies and mistakes in payroll are now a thing of the past and overtime is now being paid accurately." />
              </Content>

              <Content>
                <TextBlock text="The franchise is also making the most of Rotaready's iOS and Android apps to give store assistants greater control and visibility over their upcoming shifts. Employer/employee relationships have strengthened across the business as a result." />
              </Content>

              <Content>
                <Blockquote
                  text="Mistakes in payroll have become negligible and the app has given employees greater visibility and control over their shifts. The solution has helped us take our workforce management to the next level and made life easier for the entire business."
                  attribution="Chris Tongue - Operations Manager"
                />
              </Content>
            </Section>

            <Section>
              <PopQuote text="A combination of all of these factors has helped BAK better deploy 48 hours and 20 minutes each week and achieve an ROI of over 562%." />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Ongoing support" />
              </Content>

              <Content>
                <TextBlock text="Rotaready will continue to provide ongoing support to BAK as they grow. The e-learning platform and in-person refresher training will be readily available for both new and existing staff to ensure Rotaready is scaling with the business and BAK are getting the very best from Rotaready." />
              </Content>

              <Content>
                <Blockquote
                  text="I can't fault our experience of working with Rotaready so far. The support from the team has been fantastic and the software itself is far superior to anything we have used before. We have no regrets in choosing Rotaready as our partners."
                  attribution="Chris Tongue - Operations Manager"
                />
              </Content>
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_bak"
                label="rotaready.com/customer-stories/retail-o2-bak"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-O2-BAK.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/header-bak.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    logo: file(relativePath: { eq: "customer-stories/logo-bak.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 50, quality: 100)
      }
    }

    inline: file(relativePath: { eq: "customer-stories/inline-bak.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
